/* ImageGallery.css */

/* Styles for the carousel container */

.image-gallery-container {
    width: 100vw;
    height: 100vh;

    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: hidden; /* Ensure the container does not overflow */
    z-index: 1;
}

.scaled-image {
    max-width: 70vw; /* Adjust the maximum width of the scaled images */
    max-height: 70vh; /* Adjust the maximum height of the scaled images */
    margin: 40px auto; /* Center the images horizontally with some top margin */
    display: block;

}

.slick-slider {
    
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
  
.slick-slide img {
  width: 80%;
  height: 80%;
  object-fit:scale-down;
  border-radius: 8px;
}


  
.slick-arrow {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  z-index: 2 !important;
  color: transparent !important; /* Hide the default arrow text */
  font-size: 0 !important; /* Hide the default arrow text */
  border-radius: 25% !important;
  
  cursor: pointer !important;
  opacity: 0.7 !important; /* Set arrow opacity to make them less prominent initially */
  transition: opacity 0.3s ease !important; /* Smooth transition for arrow opacity */
}

.slick-arrow::before {
  content: "" !important; /* Remove the default arrow text */
}

.slick-arrow:hover {
  opacity: 1 !important; /* Increase opacity on hover */
}

.slick-prev {
  left: 30px !important;
  opacity: 1 !important; /* Increase opacity on hover */
  width: 0;
  height: 0;
  border-top: 30px solid transparent; /* Size of arrow */
  border-bottom: 30px solid transparent; /* Size of arrow */
  border-right: 30px solid #008000; /* Size and color of arrow */
}

.slick-next {
  right: 30px !important;
  opacity: 1 !important; /* Increase opacity on hover */
  width: 0;
  height: 0;
  border-top: 30px solid transparent; /* Size of arrow */
  border-bottom: 30px solid transparent; /* Size of arrow */
  border-left: 30px solid #008000; /* Size and color of arrow */
}



  
/* Styling for the close button */
.close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #0080FF;
        border: none;
        border-radius: 3px;
        width: 80px;
        color: white;
        padding: 5px 5px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 12px;
        margin-left: 25px;
        cursor: pointer;
    z-index: 2;
    
}

.close-btn:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transform: scale(1.1);
}

.scrollable-navigation {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent line breaks */
    z-index: 2;
}

.scrollable-navigation::-webkit-scrollbar {
    display: none; /* WebKit browsers */
}

.thumbnails {
    display: inline-flex;
    align-items: center;
    gap: 10px;
}

.thumbnail-item {
    cursor: pointer;
    z-index: 3;

}

.thumbnail-item img {
    max-width: 100px; /* Adjust the maximum width of the thumbnails */
    height: auto;
    border-radius: 5px;
    transition: transform 0.3s ease; /* Add a smooth transition effect */
}

.thumbnail-item img:hover {
    transform: scale(1.1); /* Scale up the thumbnail on hover */
}