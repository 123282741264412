/* Define custom fonts */
@font-face {
  font-family: 'AHReg';
  src: url('./fonts/AdobeHebrewRegular.otf') format('opentype');
}

@font-face {
  font-family: 'AHBold';
  src: url('./fonts/AdobeHebrewBold.otf') format('opentype');
}

/* Apply the fonts globally */
body, html {
  margin: 0;
  font-family: 'AHReg', 'AHBold', sans-serif; /* Use the font family names */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

/* Override Bootstrap's font settings */
:root {
  --bs-body-font-family: 'AHReg', sans-serif;
  --bs-body-font-size: 16px; /* Adjust font size if needed */
  --bs-body-font-weight: normal; /* Adjust font weight if needed */
  --bs-body-line-height: 1.5; /* Adjust line height if needed */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
