.container.contact-form-container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .form.contact-form {
    color: rgb(23, 29, 95);
  }
  
  .form-control {
    border-color: rgb(23, 29, 95) !important;
  }
  
  .btn.contact-form-button {
    background-color: rgb(8, 138, 79) !important;
    border-color: rgb(8, 138, 79) !important;
  }
  
  .btn.contact-form-button:hover,
  .btn.contact-form-button:focus,
  .btn.contact-form-button:active {
    background-color: rgb(6, 110, 63) !important;
    border-color: rgb(6, 110, 63) !important;
  }
  