
.body-row {
    margin-bottom: 20px;
    font-family: 'AHReg', 'AHBold', sans-serif;
}

.body-container {
    display: flex;
    flex-direction: column;
    padding: 15px;
    font-family: 'AHReg', 'AHBold', sans-serif;
}

.body-container .card {
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    text-align: left;
    
}

.body-container .card-body {
    display: flex;
    flex-direction: column;
    text-align: left;
    
}

.body-container .card-title {
    font-size: 20px;
    margin-bottom: 10px;
    
    color: rgb(23,29,95);
}

.body-container .card-text {
    margin-bottom: 15px;
    line-height: 1.6;
    text-align: left;
    font-size: 18px;
}

.body-container h2 {
    font-size: 22px;
    color: rgb(167, 12, 12);
    margin-bottom: 10px;

}

.body-container .card-text p {
    margin-bottom: 15px;
    line-height: 1.6;
    text-align: left;
    font-size: 18px;
    color: rgb(23,29,95);
    
}
.body-container li {
    font-size: 20px;
    color: rgb(23,29,95);
    margin-bottom: 10px;

}
.body-content {
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
    padding: 0px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.body-content h2 {
    font-size: 20px;
    margin-bottom: 10px;
    color: rgb(23,29,95);
}

.body-content p {
    margin-bottom: 15px;
    line-height: 1.6;
    text-align: left;
    color: rgb(23,29,95);
}

.body-city {
    background: url('./images/city.png') no-repeat center center;
    background-size: contain; /* Ensure the full image is always visible and scales to remain */
    width: 90%;
    height: 180px; /* Set the height to auto to allow JavaScript to adjust it */
    background-position: center;
    margin: auto;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-width: 180px;
    border-color: green;
    border: 18px;
   
}


.card-container .card {
    flex: 1 0 21%;  /* Or adjust this value to whatever suits your needs */
    min-width: 400px;
    border-color: green;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3); margin: 10px;
    padding: 15px;
   border-width: 3px;
    border-style: solid;
}

.content-wrapper {
    width: 100%; /* Match the width of body-city */
    margin: auto; /* Center the content */
}

.body-logo-parent {
    
    min-width: 150px;
    max-width: 150px;
    width: 100%;
    display: flex;
    justify-content: flex-start; /* Align logos to the right */
    align-items: center;
    padding-top: 0px;
    margin-bottom: 20px;
}

.body-logo-wrapper {
    
    display: flex;
    justify-content: flex-start; /* Align logos to the right */
    width: 70%;
    height: 100%;
}
.card-body-logo-parent {
    display: none;

}
.card-container .body-partners
{
    display: none;


}

.body-logo-1,
.body-logo-2 {
    
    width: auto;
    max-height: 20px;
    object-fit: contain;
}
.body-logo-container-1 {
    width: auto; /* Match the width of .logo-1 */
    height:auto; /* Set to fixed value */    
    display: flex;
    justify-content: flex-start; /* Align logos to the left */
    align-items: center; /* Center logos vertically */
    margin-right: 5%;
    
}


.body-logo-container-2 {
    width: auto; /* Match the width of .logo-2 */
    height:auto; /* Set to fixed value */
    display: flex;
    justify-content: flex-start; /* Align logos to the left */
    align-items: center; /* Center logos vertically */
    padding-top: 2px;
    margin-left: 5%;
    max-height: 20px;

}
.h1Label {
    background-color: rgb(109, 161, 107);
    color: white; /* Change the text color to white for better contrast */
    padding: 10px; /* Add some padding */
    border-radius: 5px; /* Round the corners */
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3); /* Add a shadow to the right and bottom */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add a larger shadow to the text */
    border: 1px solid rgba(0, 0, 0, 0.5); /* Add a border */
    font-weight: bold; /* Make the text bold */
    font-size: 1.2em; /* Increase the font size */
}
.h2Label {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Add a larger shadow to the text */
 
}

@media (max-width: 767px) {
  

    .body-container .card {
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    text-align: left;
}

    .body-container {
        display: flex;
        flex-direction: column;
        padding: 0px;
        width: 100%;
        text-align: left;
    }

    .body-city {
        height: 60px; /* Set the height to 120px for mobile */
        width: 80%; /* Match the width of body-city */
        background-size: cover;
        text-align: left;
    }
    
    .card-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        text-align: left;
        min-width: 320px;
        
    }

.card-container .card {
    flex: 1 0 21%;  /* Or adjust this value to whatever suits your needs */
    margin: 5px;
    padding: 5px;
    min-width: 320px;
    text-align: left;
    
}
.card-body-logo-parent {

    min-width: 150px;
    max-width: 150px;
    width: 100%;
    display: flex;
    justify-content: flex-start; /* Align logos to the right */
    align-items: center;
    padding-top: 0px;
    margin-bottom: 20px;
}

.card-body-logo-wrapper {

    display: flex;
    justify-content: flex-start; /* Align logos to the right */
    width: 70%;
    height: 100%;
}

}