

.header {
   
    height: 100%;
    padding: 10px;
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    font-family: 'AHReg', 'AHBold', sans-serif;
}

.container {
    padding: 0 !important; /* Remove padding */
    margin: 0 !important; /* Remove margin */
    display: flex; /* Enable flexbox */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    font-family: 'AHReg', 'AHBold', sans-serif;
}

.horizontal-line-container {
    width: 100%;
    max-height: 35px;
    display: flex;
    justify-content: center; /* Center items horizontally */
    align-items: center;
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margin */
    vertical-align: center;
}

.horizontal-line {
    width: calc(90% - 10px);
    max-height: 35px;
    background: linear-gradient(to right, rgb(23,29,95) 0%, rgb(08, 138, 79) 100%);
    position: relative;
    vertical-align: center;
    
}

.horizontal-line::after {
    content: "";
    position: absolute;
    right: -10px; /* Position it at the end of the line */
    top: 0;
    width: 20px; /* Make it the same height as the line */
    max-height: 35px;
    border-radius: 50%; /* Make it circular */
    background-color: #ffffff; /* Make it the same color as the line */
   
}

.circles-container {
    display: flex;
    justify-content: center; /* Center items horizontally */
    align-items: center;
    width: 20%; /* Adjust this value if necessary */
    height: 100%;
    margin-left: 10px; /* Add space between the line and the circles */
}

.circle {
    width: 35px;
    height: 35px;
    border-radius: 50%; /* Make the div circular */
    background-color: rgb(16, 70, 87); /* Set the color to the middle color of the gradient */
    margin-left: 10px; /* Add space to the left of the circles */
}

.circle:first-child {
    width: 35px;
    height: 35px;
    border-radius: 50%; /* Make the div circular */
    background-color: rgb(23,29,95); /* Set the color to the start color of the gradient */
    margin-left: 10px; /* Add space to the left of the circles */
}

.circle:nth-child(2) {
    width: 35px;
    height: 35px;
    border-radius: 50%; /* Make the div circular */
    background-color:rgb(23,29,95); /* Set the color to the middle color of the gradient */
    margin-right: 2px; /* Add space between the circles */
}

.circle:last-child {
    width: 35px;
    height: 35px;
    border-radius: 50%; /* Make the div circular */
    background-color: rgb(8,138,79); /* Set the color to the end color of the gradient */
    margin-right: 2px; /* Add space between the circles */
}

.my-auto {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    width: auto;
    font-family: 'AHReg', 'AHBold', sans-serif;
}

.row-height {
    max-height: 100%; /* Define the maximum height of the row as 100% of its parent */
}

.logo-gtd-container {
    height: 100%; /* Adapt to the height of the header */
    width: auto; /* Adapt to the width of the image */
    display: flex;
    margin-left: 2%;
}

.logo-gtd {
    height: 100%;
    max-height: 110px;
    width: auto;
    margin: 5%;
    object-fit: contain; /* Ensure the image is resized to fit within its container while maintaining aspect ratio */
}

.vertical-line-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    min-width: none;
    color: #d5d5e7;
    vertical-align: center;
}

.vertical-line {
    border-left: 1px solid black;
    height: 50%;
    font-size: 16px;;
    color: #d5d5e7;
    vertical-align: center;
}

.logo-parent {
    min-width: 250px;
    width: auto;
    display: flex;
    justify-content: flex-end; /* Align logos to the right */
    align-items: center;
    padding-top: 50px;
}

.logo-wrapper {
    display: flex;
    width: auto;
    height: 100%;
}

.logo-1,
.logo-2 {
    width: auto;
    max-height: 50px;
    object-fit: contain;
}


.logo-container-1 {
    width: auto; /* Match the width of .logo-1 */
    height:auto; /* Set to fixed value */    
    display: flex;
    justify-content: flex-end; /* Align logos to the left */
    align-items: center; /* Center logos vertically */
    margin-right: 5%;
}


.logo-container-2 {
    width: auto; /* Match the width of .logo-2 */
    height:auto; /* Set to fixed value */
    display: flex;
    justify-content: flex-end; /* Align logos to the left */
    align-items: center; /* Center logos vertically */
    padding-top: 2px;
    margin-left: 5%;

}

p {
    text-align: center;
    width: 100%;
    order: 2; /* Display below the logos */
    font-size: 14px;
    font-family: 'AHReg', 'AHBold', sans-serif;
}

h6 {
    font-family: "Adobe Hebrew";
    font-size: 1.8vmin; /* Change from vw to vmin */
    font-weight: 600;
    color: rgb(23,29,95); 
    font-family: 'AHReg', 'AHBold', sans-serif;
}

/* Target ul elements within the header component */
.header-list {
    list-style-type: none; /* Remove default bullet points */
    font-family: 'AHReg', 'AHBold', sans-serif;
    width: 280px;
}

/* Target li elements within the header component */
.header-list-item {
    font-family: "Adobe Hebrew";
    font-size: 1.8vmin; /* Change from vw to vmin */
    font-weight: 600;
    color: rgb(16, 70, 87); /* Set the color to the middle color of the gradient */
    position: relative; /* Enable absolute positioning for child elements */
    padding-left: 0px; /* Add space for the custom bullet point */
    left: -10px;
    line-height: 1.2; /* Reduce line spacing */
    font-family: 'AHReg', 'AHBold', sans-serif;
}

/* Style the bullet point for li elements within the header component */
.header-list-item::before {
    content: ""; /* Required for the pseudo-element to be displayed */
    position: absolute; /* Position the bullet point relative to the li element */
    left: -20px;
    top: 50%;
    transform: translateY(-50%); /* Center the bullet point vertically */
    width: 10px; /* Set the size of the bullet point */
    height: 10px;
    border: 2px solid currentColor; /* Create a hollow circle */
    border-radius: 50%; /* Make the bullet point circular */
}

.text-container {
    max-width: 250px;
    min-width: 160px;
    margin-left: 5%;
    height: 100%;
    margin-top: 9px;
    margin-right: 5px;
    margin-left: 6%;
    display: flex; /* Enable flexbox */
    flex-direction: column; /* Stack items vertically */
    justify-content: center; /* Center items vertically */
    font-family: 'AHReg', 'AHBold', sans-serif;

}

.navbar
{
    height: 55px;
  
  }
.navbar-nav
{
    height: 35px;
  
  }
.header-nav-link {

 font-size: 20px; 
 color: #e9e9eb; 
 font-family: 'AHReg', 'AHBold', sans-serif;
 max-width: 400px;

}
.header-nav-link:hover {

    font-size: 20px; 
    color:  rgb(78, 199, 145); 

   }

/* Override Bootstrap's default active link color */
.navbar .header-nav-link.active
{
    color:#e9e9eb; 
    vertical-align: center;
    padding-bottom: 20px;
  }
.navbar .header-nav-link.active:hover {
  color: rgb(78, 199, 145); /* Color for the selected (active) link */
  /* You can also add other styles here, such as background color */
}

.contact-button {
    display: none;
  }
/* Media query for smaller screens */
@media screen and (max-width: 768px) {
    .header {
     
     
        padding: 10px;
        justify-content: center; /* Center items horizontally */
        align-items: center; /* Center items vertically */
    }
    
    .header-list {
        margin-bottom: 5px;
       }
    .container {
   
        padding: 0 !important; /* Remove padding */
        margin: 0 !important; /* Remove margin */
        display: flex; /* Enable flexbox */
        justify-content: center; /* Center items horizontally */
        align-items: center; /* Center items vertically */
    }
    
    .horizontal-line-container {
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: center; /* Center items horizontally */
        align-items: center;
        padding: 0; /* Remove padding */
        margin: 0; /* Remove margin */
    }
    
    .horizontal-line {
        width: calc(80% - 10px);
        height: 15px;
        background: linear-gradient(to right, rgb(23,29,95) 0%, rgb(08, 138, 79) 100%);
        position: relative;
    }
    
    .horizontal-lineS::after {
        content: "";
        position: absolute;
        right: -10px; /* Position it at the end of the line */
        top: 0;
        width: 20px; /* Make it the same height as the line */
        height: 15px; /* Make it the same height as the line */
        border-radius: 50%; /* Make it circular */
        background-color: #ffffff; /* Make it the same color as the line */
    }
    
    .my-auto {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        max-width: 180px;
  
        
    }

    .circles-container {
        display: flex;
        justify-content: center; /* Center items horizontally */
        align-items: center;
        width: 20%; /* Adjust this value if necessary */
        height: 100%;
        margin-left: 10px; /* Add space between the line and the circles */
    }
    
    .circle {
        width: 15px;
        height: 15px;
        border-radius: 50%; /* Make the div circular */
        background-color: rgb(16, 70, 87); /* Set the color to the middle color of the gradient */
        margin-left: 10px; /* Add space to the left of the circles */
    }
    
    .circle:first-child {
        width: 15px;
        height: 15px;
        border-radius: 50%; /* Make the div circular */
        background-color: rgb(23,29,95); /* Set the color to the start color of the gradient */
        margin-left: 10px; /* Add space to the left of the circles */
    }
    
    .circle:nth-child(2) {
        width: 15px;
        height: 15px;
        border-radius: 50%; /* Make the div circular */
        background-color:rgb(23,29,95); /* Set the color to the middle color of the gradient */
        margin-right: 2px; /* Add space between the circles */
    }
    
    .circle:last-child {
        width: 15px;
        height: 15px;
        border-radius: 50%; /* Make the div circular */
        background-color: rgb(8,138,79); /* Set the color to the end color of the gradient */
        margin-right: 2px; /* Add space between the circles */
    }
  
  .vertical-line-container {
    display: none; /* Hide the text container */

    }

    .vertical-line {
    display: none; /* Hide the text container */

    }

    .logo-gtd-container {
    height: 80%; /* Adapt to the height of the header */
    width: 45%; /* Adapt to the width of the image */
    display: flex;
    margin-left: 2%;
    }

    .logo-gtd {
        height: 80%;
        max-height: 100px;
        width: 100%;
        margin: 5%;
        object-fit: contain; /* Ensure the image is resized to fit within its container while maintaining aspect ratio */
    }



    .logo-parent {
        display: none; /* Hide the text container */
    }
    .header-nav-link {
        display: none; /* Hide the link on smaller screens */
      }
   
      .contact-button {
        background-color: #0080FF;
        border: none;
        border-radius: 3px;
        width: 80px;
        color: white;
        padding: 5px 5px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 12px;
        margin-left: 25px;
        cursor: pointer;
      }

}