/* Footer.css */
.footer-row {

  padding: 20px;
  font-family: 'AHReg', 'AHBold', sans-serif;
}

.footer-row:first-child {
  border-top-left-radius: 10px; /* Set the radius for the top left corner */
  border-top-right-radius: 10px; /* Set the radius for the top right corner */
  height: 90px;
  background-color: rgb(8, 116, 67); /* Set the color to the end color of the gradient */

  
}

.footer-row:nth-child(2) {
  height: 100px;
  background-color: rgb(29, 97, 223);
  padding: 10px;
}

.footer-row:last-child {
  height: 70px;
  background-color: rgb(12, 17, 66);
  padding: 10px;
}

.first-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 20px;
}

.email-line {
  font-size: 30px;
  font-weight: 500;
  color: #d8d6d6;
  margin-bottom: 20px;

}

.website-line {
    text-align: center;
    line-height: 1.9;
    font-size: 26px;
    font-weight: 500;
    color: #d8d6d6;
  
  }

.middle-row {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  
  }

  .phone-line {
    font-size: 30px;
    font-weight: 500;
    color: #d8d6d6;
    margin-top: 10px;

  }

.last-row {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.copyright {
  font-size: 18px;
  font-weight: 500;
  color: #d8d6d6;

}

.address-line {
    font-size: 20px;
    font-weight: 300;
    color: #d8d6d6;
  
  }

  @media screen and (max-width: 768px) {

    
.footer-row:first-child {
  border-top-left-radius: 10px; /* Set the radius for the top left corner */
  border-top-right-radius: 10px; /* Set the radius for the top right corner */
  height: 90px;
  background-color: rgb(8, 116, 67); /* Set the color to the end color of the gradient */

  
}

.footer-row:nth-child(2) {
  height: 80px;
  background-color: rgb(29, 97, 223);
  padding: 10px;

}

.footer-row:last-child {
  height: 70px;
  background-color: rgb(12, 17, 66);
  padding: 10px;
}


.address-line {
  font-size: 12px;


}

.phone-line {
  font-size: 18px;
  font-weight: 500;
  color: #d8d6d6;
  margin-top: 0px;

}
.email-line {
  font-size: 18px;
  font-weight: 500;
  color: #d8d6d6;
  margin-bottom: 0px;

}

  }