.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjust the minmax value as needed */
  gap: 10px;
  justify-content: space-around; /* Add this line */
}

@media (min-width: 768px) {
  .project-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .project-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.project-card {
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  text-decoration: none;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  text-align: center; /* Center the text horizontally */
}

.project-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease; /* Add a smooth transition for the image */

}

.project-card .project-card-text::before {
  white-space: pre; /* Preserve whitespace */
}


.project-card::after {
  content: ''; /* Create the overlay */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Add a semi-transparent background */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.5s ease; /* Add a smooth transition for the overlay */
}

.project-card:hover img {
  transform: scale(1.1); /* Enlarge the image on hover */
}

.project-card:hover::after {
  opacity: 1; /* Show the overlay on hover */
}

/* Add styles for the text overlay */
.project-card .project-card-text {
  position: absolute;
  top: 50%; /* Center the text vertically */
  left: 50%; /* Center the text horizontally */
  transform: translate(-50%, -50%);
  color:#fff;
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s ease;
  font-size: 2px; /* Add the initial font size */
  animation: none; /* Remove the animation property */
  z-index: 1; /* Set a higher z-index value to bring the text to the front */
  white-space: pre-line; /* Preserve line breaks */
}

.project-card .project-card-text {
  animation: none; /* Remove the animation property */
  
}

.project-card:hover .project-card-text {
  opacity: 1; /* Show the text on hover */
  animation: bounce 1.0s ease-in-out both; /* Apply the animation on hover */
}

.no-scroll {
  overflow: hidden;
}


@keyframes bounce {
  0% {
    transform: scale(1) translate(-50%, -50%);
  }
  50% {
    transform: scale(1.2) translate(-50%, -50%);
  }
  100% {
    transform: scale(1) translate(-50%, -50%);
    font-size: 22px; /* Increase the font size by 20px at the end of the bounce */
  }
}