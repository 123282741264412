.hero-carousel {

  position: relative;
  width: 100%;
  height: 850px; /* Set the height to 500px */
margin-left: auto;
margin-right: auto;
}

.hero-carousel .carousel-item {
  position: relative;
  height: 850px; /* Set the height to 50% of the viewport height */
  width: 100%;
}

.hero-carousel .carousel-item img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%; /* Set the width to 100% */
  height: 850px; /* Set the height to auto to maintain aspect ratio */
  object-fit: cover; /* Use object-fit cover to maintain the aspect ratio and fill the container */
}

.carousel-inner {
  height: 850px; /* Set the height to 50% of the viewport height */
}

.hero-carousel .carousel-caption {
  position: absolute;
  bottom: 20vh; /* Set the bottom position to 20% of the viewport height */
  left: 0;
  right: 0;
  text-align: center;
  width: 100%;
}

.hero-carousel .carousel-caption h3.animated {
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-name: fadeInLeft;
  animation-delay: 1s;
  font-size: 80px;
  font-weight: 500;
  padding: 10px,0px,10px,0px;
}

.hero-carousel .carousel-caption p.animated {
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-name: fadeInRight;
  animation-delay: 2s;
  font-size: 30px;
  background-color: rgb(22, 88, 22, 0.6);
  padding: 20px;
 
}

@media (max-width: 767px) {
 


  .hero-carousel {
  
    position: relative;
    width: 100%;
    height: 400px; /* Set the height to 500px */

  }
  
  .hero-carousel .carousel-item {
    position: relative;
    height: 400px; /* Set the height to 50% of the viewport height */
    width: 100%;
  }
  
  .hero-carousel .carousel-item img {
    
  
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; /* Set the width to 100% */
      height: 400px; /* Set the height to auto to maintain aspect ratio */
  
      object-fit: cover; /* Use object-fit cover to maintain the aspect ratio and fill the container */
  }
  .carousel-inner {
    height: 400px; /* Set the height to 50% of the viewport height */
  }
  
  .hero-carousel .carousel-caption {
    position: absolute;
    bottom: 40vh; /* Set the bottom position to 20% of the viewport height */
    left: 0;
    right: 0;
    text-align: center;
    padding: 0px;
    margin: 0px;
    width: 100%;
    top: 50%;
  }
  
  .hero-carousel .carousel-caption h3.animated {
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-name: fadeInLeft;
    animation-delay: 1s;
    font-size: 24px;
    font-weight: 800;
    padding: 10px,0px,10px,0px;
  }
  
  .hero-carousel .carousel-caption p.animated {
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-name: fadeInRight;
    animation-delay: 2s;
    font-size: 14px;
    background-color: rgb(22, 88, 22, 0.7);
    border-radius: 2px;
    padding: 20px;

   
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-10%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(10%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}